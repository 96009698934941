import { history as historyRouter } from "instantsearch.js/es/lib/routers";
import CONFIG from "config";

const INDEX_NAME = CONFIG.ALGOLIA_INDEX;

const routeParams = [
  "year",
  "price",
  "mileage",
  "highway_mpg",
  "city_mpg",
  "make",
  "model",
  "fuel",
  "color",
  "drivetrain_description",
  "body",
  "feature_list",
  "trim",
  "transmission",
  "certified",
  "certification",
  "query",
  "engine",
  "page",
  "door_count",
  "cylinders",
  "sortBy",
  "interior_material",
];

export const routingLogic = {
  router: historyRouter( {
    windowTitle( routeState ) {
      const metaDescription = document.querySelector( "meta[name='description']" );
      const addDescription = ( description ) => {
        const descMetaTag = document.createElement( "meta" );
        descMetaTag.name = "description";
        descMetaTag.content = description;
        document.head.appendChild( descMetaTag );
      };
      const setDescription = ( description ) => {
        if (!metaDescription) addDescription( description );
        else metaDescription.content = description;
      };
      const {
        certification: certParam,
        fuel: fuelParam,
        query: queryParam,
      } = routeState;
      if ( queryParam !== '' ) {
        if (
          fuelParam &&
          fuelParam.length > 0 &&
          ( fuelParam.reduce( ( acc, next ) => {
            if ( acc ) return acc;
            if ( next.toLowerCase().includes( "electric" ) ) return true;
            if ( next.toLowerCase().includes( "hybrid" ) ) return true;
            return false;
          }, false ) )
        ) {
          setDescription( "Buy or lease a pre-owned Honda electric or Honda hybrid vehicle today at Clawson Honda. Located in Fresno, CA, we'lll make sure you enjoy your next drive." );
          return "Pre-Owned Electric & Hybrid Vehicles | Clawson Honda";
        } else {
          if (
            certParam &&
            certParam.length > 0 &&
            certParam.includes( "Clawson Customs" )
          ) {
            setDescription( "Shop our inventory of custom pre-owned Honda vehicles at Clawson Honda in Fresno, CA. Contact us with questions or visit us for a test drive!" );
            return "Custom Pre-Owned Vehicles for Sale | Honda Dealership near Clovis, CA";
          } else if ( certParam && certParam.length > 0 ) {
            setDescription( "Shop our inventory of certified pre-owned Honda vehicles at Clawson Honda in Fresno, CA. Contact us with questions or visit us for a test drive!" );
            return "Certified Pre Owned Vehicles for Sale | Clawson Honda";
          }
        }
        setDescription( "Buy or lease a pre-owned Honda vehicle at Clawson Honda of Fresno, CA. Shop our inventory of cars, sedans, trucks, and SUVs or visit our Honda dealership today!" );
        return "Pre-Owned Vehicles for Sale | Honda Dealership | Clovis, CA";
      }
    },
    createURL({ qsModule, location, routeState }) {
      const urlParts = location.href.match(/^(.*?)\/search/);
      const baseUrl = `${urlParts ? urlParts[1] : ""}/`;
      const queryParameters = {};

      routeParams.map((param) => {
        if (routeState[param]) {
          queryParameters[param] = Array.isArray(routeState[param])
            ? routeState[param].map(encodeURIComponent)
            : encodeURIComponent(routeState[param]);
        }
      });

      const queryString = qsModule.stringify(queryParameters, {
        addQueryPrefix: true,
        arrayFormat: "repeat",
      });

      return `${baseUrl}search/${queryString}`;
    },
    parseURL({ qsModule, location }) {
      const {
        query = "",
        page,
        make = [],
        model = [],
        fuel = [],
        color = [],
        drivetrain_description = [],
        body = [],
        mileage = "",
        year = "",
        price = "",
        highway_mpg = "",
        city_mpg = "",
        feature_list = [],
        trim = [],
        transmission = [],
        certified = "",
        certification = [],
        engine = [],
        door_count = [],
        cylinders = [],
        interior_material = [],
        sortBy = "",
      } = qsModule.parse(location.search.slice(1));

      //* qs does not return an array when there is a single value.
      const allMakes = Array.isArray(make) ? make : [make].filter(Boolean);
      const allModel = Array.isArray(model) ? model : [model].filter(Boolean);
      const allFuels = Array.isArray(fuel) ? fuel : [fuel].filter(Boolean);
      const allColor = Array.isArray(color) ? color : [color].filter(Boolean);
      const allDrivetrains = Array.isArray(drivetrain_description)
        ? drivetrain_description
        : [drivetrain_description].filter(Boolean);
      const allBodies = Array.isArray(body) ? body : [body].filter(Boolean);
      const allFeatures = Array.isArray(feature_list)
        ? feature_list
        : [feature_list].filter(Boolean);
      const allTrims = Array.isArray(trim) ? trim : [trim].filter(Boolean);
      const allTransmissions = Array.isArray(transmission)
        ? transmission
        : [transmission].filter(Boolean);
      const allCerts = Array.isArray(certification)
        ? certification
        : [certification].filter(Boolean);
      const allEngines = Array.isArray(engine)
        ? engine
        : [engine].filter(Boolean);
      const allDoors = Array.isArray(door_count)
        ? door_count
        : [door_count].filter(Boolean);
      const allCylinders = Array.isArray(cylinders)
        ? cylinders
        : [cylinders].filter(Boolean);
      const allMaterials = Array.isArray(interior_material)
        ? interior_material
        : [interior_material].filter(Boolean);

      return {
        query: decodeURIComponent(query),
        page: page,
        make: allMakes.map(decodeURIComponent),
        model: allModel.map(decodeURIComponent),
        fuel: allFuels.map(decodeURIComponent),
        color: allColor.map(decodeURIComponent),
        drivetrain_description: allDrivetrains.map(decodeURIComponent),
        body: allBodies.map(decodeURIComponent),
        mileage: decodeURIComponent(mileage),
        year: decodeURIComponent(year),
        price: decodeURIComponent(price),
        highway_mpg: decodeURIComponent(highway_mpg),
        city_mpg: decodeURIComponent(city_mpg),
        feature_list: allFeatures.map(decodeURIComponent),
        trim: allTrims.map(decodeURIComponent),
        transmission: allTransmissions.map(decodeURIComponent),
        certified: decodeURIComponent(certified),
        certification: allCerts.map(decodeURIComponent),
        engine: allEngines.map(decodeURIComponent),
        door_count: allDoors.map(decodeURIComponent),
        cylinders: allCylinders.map(decodeURIComponent),
        sortBy: decodeURIComponent(sortBy),
        interior_material: allMaterials.map(decodeURIComponent),
      };
    },
    writeDelay: 700,
  }),

  stateMapping: {
    stateToRoute(uiState) {
      const indexUiState = uiState[INDEX_NAME] || {};

      return {
        query: indexUiState.query,
        page: indexUiState.page,
        make: indexUiState.refinementList && indexUiState.refinementList.make,
        model: indexUiState.refinementList && indexUiState.refinementList.model,
        fuel: indexUiState.refinementList && indexUiState.refinementList.fuel,
        color: indexUiState.refinementList && indexUiState.refinementList.color,
        drivetrain_description:
          indexUiState.refinementList &&
          indexUiState.refinementList.drivetrain_description,
        body: indexUiState.refinementList && indexUiState.refinementList.body,
        year: indexUiState.range && indexUiState.range.year,
        price: indexUiState.range && indexUiState.range.price,
        mileage: indexUiState.range && indexUiState.range.mileage,
        highway_mpg: indexUiState.range && indexUiState.range.highway_mpg,
        city_mpg: indexUiState.range && indexUiState.range.city_mpg,
        feature_list:
          indexUiState.refinementList &&
          indexUiState.refinementList.feature_list,
        trim: indexUiState.refinementList && indexUiState.refinementList.trim,
        transmission:
          indexUiState.refinementList &&
          indexUiState.refinementList.transmission,
        certified: indexUiState.menu && indexUiState.menu.certified,
        certification:
          indexUiState.refinementList &&
          indexUiState.refinementList.certification,
        engine:
          indexUiState.refinementList && indexUiState.refinementList.engine,
        door_count:
          indexUiState.refinementList && indexUiState.refinementList.door_count,
        cylinders:
          indexUiState.refinementList && indexUiState.refinementList.cylinders,
        sortBy: indexUiState.sortBy,
        interior_material:
          indexUiState.refinementList &&
          indexUiState.refinementList.interior_material,
      };
    },

    routeToState(routeState) {
      const stateObject = {
        query: routeState.query,
        page: routeState.page,
        menu: {
          certified: routeState.certified,
        },
        range: {
          year: routeState.year,
          price: routeState.price,
          mileage: routeState.mileage,
          highway_mpg: routeState.highway_mpg,
          city_mpg: routeState.city_mpg,
        },
        refinementList: {
          make: routeState.make,
          model: routeState.model,
          fuel: routeState.fuel,
          color: routeState.color,
          drivetrain_description: routeState.drivetrain_description,
          body: routeState.body,
          feature_list: routeState.feature_list,
          trim: routeState.trim,
          transmission: routeState.transmission,
          certification: routeState.certification,
          engine: routeState.engine,
          door_count: routeState.door_count,
          cylinders: routeState.cylinders,
          interior_material: routeState.interior_material,
        },
        sortBy: routeState.sortBy,
      };

      return {
        truck_center__clawson_search_production: stateObject,
      };
    },
  },
};
