var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ais-instant-search",
    {
      attrs: {
        "index-name": _vm.indexName,
        "search-client": _vm.searchClient,
        routing: _vm.routing,
      },
    },
    [
      _c(
        "div",
        { staticClass: "searchPage container-fluid container pb-4" },
        [
          _c("ais-configure", { attrs: { hitsPerPage: 24 } }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { attrs: { id: "autoHubBanner" } }),
          ]),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mb-5" },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-none d-lg-block px-0",
                  attrs: { id: "desktopMenu", lg: "2" },
                },
                [
                  _c(
                    "b-card",
                    { staticClass: "sticky-menu" },
                    [
                      _c(
                        "b-row",
                        { staticClass: "my-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("ais-sort-by", {
                                staticClass: "clawson-sort-by",
                                attrs: {
                                  attribute: "sortBy",
                                  items: [
                                    { value: _vm.indexName, label: "Sort by" },
                                    {
                                      value: _vm.indexName + "_price_asc",
                                      label: "Price (Lowest)",
                                    },
                                    {
                                      value: _vm.indexName + "_price_desc",
                                      label: "Price (Highest)",
                                    },
                                    {
                                      value: _vm.indexName + "_year_desc",
                                      label: "Year (Newest)",
                                    },
                                    {
                                      value: _vm.indexName + "_year_asc",
                                      label: "Year (Oldest)",
                                    },
                                    {
                                      value: _vm.indexName + "_mileage_asc",
                                      label: "Mileage (Lowest)",
                                    },
                                    {
                                      value: _vm.indexName + "_mileage_desc",
                                      label: "Mileage (Highest)",
                                    },
                                  ],
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("ais-search-box", {
                                staticClass: "mt-3 mb-2 mb-sm-0 my-md-0",
                                attrs: { placeholder: "Search all results" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "h2",
                                { staticClass: "clawson-filter-title mb-0" },
                                [_vm._v("Filters")]
                              ),
                              _vm._v(" "),
                              _c("ais-stats", {
                                ref: "statsRef",
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ nbHits }) {
                                      return [
                                        _c(
                                          "h3",
                                          {
                                            staticClass: "clawson-filter-stats",
                                          },
                                          [_vm._v(_vm._s(nbHits) + " results")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c(
                                "ais-clear-refinements",
                                {
                                  attrs: {
                                    "excluded-attributes": ["new"],
                                    "class-names": {
                                      "ais-ClearRefinements-button":
                                        "clearFiltersBtn btn btn-sm btn-link p-0",
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "resetLabel" },
                                      slot: "resetLabel",
                                    },
                                    [_vm._v("Clear Filters")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12", id: "refineLabels" } },
                            [
                              _c("ais-current-refinements", {
                                attrs: { "excluded-attributes": ["new"] },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ items, createURL }) {
                                      return [
                                        _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "ais-currentRefinements-list",
                                          },
                                          _vm._l(items, function (item) {
                                            return _c(
                                              "li",
                                              {
                                                key: item.attribute,
                                                staticClass:
                                                  "ais-currentRefinements-attList",
                                              },
                                              _vm._l(
                                                item.refinements,
                                                function (refinement, index) {
                                                  return _c(
                                                    "button",
                                                    {
                                                      key: [
                                                        refinement.attribute,
                                                        refinement.type,
                                                        refinement.value,
                                                        refinement.operator,
                                                      ].join(":"),
                                                      staticClass:
                                                        "ais-currentrefinements-delete ais-currentRefinements-item",
                                                      attrs: {
                                                        href: createURL(
                                                          refinement
                                                        ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return item.refine(
                                                            refinement
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "ais-currentRefinements-label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                _vm.transformCurrentRefinementLabel(
                                                                  refinement
                                                                )
                                                              ) +
                                                              "\n                            "
                                                          ),
                                                          index == 0 &&
                                                          index + 1 ==
                                                            item.refinements
                                                              .length
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      _vm.getRefinementOperator(
                                                                        refinement
                                                                      )
                                                                    ) +
                                                                    "\n                            "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          index + 1 ==
                                                          item.refinements
                                                            .length
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ml-1",
                                                                },
                                                                [
                                                                  refinement.attribute ==
                                                                  "highway_mpg"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "MPG (HWY)"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  refinement.attribute ==
                                                                  "city_mpg"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "MPG (CITY)"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c("b-icon", {
                                                            attrs: {
                                                              icon: "x",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          }),
                                          0
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("refinement-list", {
                        attrs: {
                          title: "Certification",
                          attrs: [{ name: "certification" }],
                        },
                      }),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("refinement-list", {
                        attrs: {
                          title: "Make & Model",
                          attrs: [
                            {
                              name: "make",
                              header: true,
                            },
                            {
                              name: "model",
                              header: true,
                            },
                          ],
                        },
                      }),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("refinement-list", {
                        attrs: { title: "Trim", attrs: [{ name: "trim" }] },
                      }),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("vue-slider-comp", {
                        attrs: { title: "Price", attrs: [{ name: "price" }] },
                      }),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("vue-slider-comp", {
                        attrs: {
                          title: "Year & Mileage",
                          attrs: [
                            {
                              name: "year",
                              header: true,
                            },
                            {
                              name: "mileage",
                              header: true,
                            },
                          ],
                        },
                      }),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("refinement-list", {
                        attrs: {
                          title: "Body Type",
                          attrs: [{ name: "body" }],
                        },
                      }),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("vue-slider-comp", {
                        attrs: {
                          title: "Miles Per Gallon",
                          attrs: [
                            {
                              name: "highway_mpg",
                              header: true,
                            },
                            {
                              name: "city_mpg",
                              header: true,
                            },
                          ],
                        },
                      }),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("refinement-list", {
                        attrs: {
                          title: "Drive Train",
                          attrs: [{ name: "drivetrain_description" }],
                        },
                      }),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("refinement-list", {
                        attrs: {
                          title: "Cylinders",
                          attrs: [{ name: "cylinders" }],
                        },
                      }),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("refinement-list", {
                        attrs: {
                          title: "Door Count",
                          attrs: [{ name: "door_count" }],
                        },
                      }),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("refinement-list", {
                        attrs: {
                          title: "Interior Material",
                          attrs: [{ name: "interior_material" }],
                        },
                      }),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("refinement-list", {
                        attrs: {
                          title: "Exterior Color",
                          attrs: [{ name: "color", colorItem: true }],
                        },
                      }),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("refinement-list", {
                        attrs: {
                          title: "Fuel Type & Engine",
                          attrs: [
                            {
                              name: "fuel",
                              header: true,
                            },
                            {
                              name: "engine",
                              header: true,
                            },
                          ],
                        },
                      }),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("refinement-list", {
                        attrs: {
                          title: "Transmission",
                          attrs: [{ name: "transmission" }],
                        },
                      }),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("refinement-list", {
                        attrs: {
                          title: "Features",
                          attrs: [{ name: "feature_list" }],
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                {
                  staticClass: "d-block d-lg-none",
                  attrs: { id: "mobileMenu", cols: "12" },
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "my-2 align-items-center" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("ais-sort-by", {
                            staticClass: "clawson-sort-by",
                            attrs: {
                              attribute: "sortBy",
                              items: [
                                { value: _vm.indexName, label: "Sort by" },
                                {
                                  value: _vm.indexName + "_price_asc",
                                  label: "Price (Lowest)",
                                },
                                {
                                  value: _vm.indexName + "_price_desc",
                                  label: "Price (Highest)",
                                },
                                {
                                  value: _vm.indexName + "_year_desc",
                                  label: "Year (Newest)",
                                },
                                {
                                  value: _vm.indexName + "_year_asc",
                                  label: "Year (Oldest)",
                                },
                                {
                                  value: _vm.indexName + "_mileage_asc",
                                  label: "Mileage (Lowest)",
                                },
                                {
                                  value: _vm.indexName + "_mileage_desc",
                                  label: "Mileage (Highest)",
                                },
                              ],
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    { staticClass: "my-2" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("ais-search-box", {
                            staticClass: "mt-3 mb-2 mb-sm-0 my-md-0",
                            attrs: { placeholder: "Search all results" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    { staticClass: "my-2" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.mobileFilterModal",
                                  modifiers: { mobileFilterModal: true },
                                },
                              ],
                              staticClass: "filterBtn",
                              attrs: { block: "", id: "mobileFilterTrigger" },
                            },
                            [
                              _c("b-icon", { attrs: { icon: "sliders" } }),
                              _vm._v(
                                "\n                Filter\n              "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("ais-current-refinements", {
                            attrs: { "excluded-attributes": ["new"] },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ items, createURL }) {
                                  return [
                                    _c(
                                      "ul",
                                      {
                                        staticClass:
                                          "ais-currentRefinements-list",
                                      },
                                      _vm._l(items, function (item) {
                                        return _c(
                                          "li",
                                          {
                                            key: item.attribute,
                                            staticClass:
                                              "ais-currentRefinements-attList",
                                          },
                                          _vm._l(
                                            item.refinements,
                                            function (refinement, index) {
                                              return _c(
                                                "button",
                                                {
                                                  key: [
                                                    refinement.attribute,
                                                    refinement.type,
                                                    refinement.value,
                                                    refinement.operator,
                                                  ].join(":"),
                                                  staticClass:
                                                    "ais-currentrefinements-delete ais-currentRefinements-item",
                                                  attrs: {
                                                    href: createURL(refinement),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return item.refine(
                                                        refinement
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ais-currentRefinements-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.transformCurrentRefinementLabel(
                                                              refinement
                                                            )
                                                          ) +
                                                          "\n                          "
                                                      ),
                                                      index == 0 &&
                                                      index + 1 ==
                                                        item.refinements.length
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  _vm.getRefinementOperator(
                                                                    refinement
                                                                  )
                                                                ) +
                                                                "\n                          "
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      index + 1 ==
                                                      item.refinements.length
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-1",
                                                            },
                                                            [
                                                              refinement.attribute ==
                                                              "highway_mpg"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "MPG (HWY)"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              refinement.attribute ==
                                                              "city_mpg"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "MPG (CITY)"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c("b-icon", {
                                                        attrs: { icon: "x" },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    { staticClass: "my-2" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("ais-stats", {
                            ref: "statsRef",
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ nbHits }) {
                                  return [
                                    _c(
                                      "h3",
                                      { staticClass: "clawson-filter-stats" },
                                      [_vm._v(_vm._s(nbHits) + " results")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "ais-clear-refinements",
                            {
                              attrs: {
                                "excluded-attributes": ["new"],
                                "class-names": {
                                  "ais-ClearRefinements-button":
                                    "clearFiltersBtn btn btn-sm btn-link p-0",
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "resetLabel" },
                                  slot: "resetLabel",
                                },
                                [_vm._v("Clear Filters")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { cols: "12", lg: "10" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("ais-state-results", {
                            staticClass: "w-100",
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({
                                  state: { query },
                                  results: { hits },
                                }) {
                                  return [
                                    hits.length > 0
                                      ? _c("ais-hits", {
                                          attrs: { escapeHTML: false },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ items }) {
                                                  return [
                                                    _c(
                                                      "b-card-group",
                                                      { attrs: { deck: "" } },
                                                      _vm._l(
                                                        items,
                                                        function (item) {
                                                          return _c(
                                                            "new-vehicle-card",
                                                            {
                                                              key: item.objectID,
                                                              attrs: {
                                                                car: item,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      : _c("div", { staticClass: "mt-5" }, [
                                          _c(
                                            "h2",
                                            { staticClass: "text-center" },
                                            [_vm._v("No Results Found")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                "\n                      Please try adjusting your search criteria.\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            { staticClass: "text-center" },
                                            [_vm._v(_vm._s(query))]
                                          ),
                                        ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("ais-pagination", {
                            on: { "page-change": _vm.onPageChange },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            {
              staticClass: "mx-auto mb-5",
              staticStyle: { "max-width": "1440px" },
            },
            [
              _c(
                "b-col",
                { staticClass: "disclaimer-container", attrs: { cols: "12" } },
                [
                  _c("div", { staticClass: "disclaimer-section mb-4 px-7" }, [
                    _c("p", [
                      _vm._v(
                        "\n\t\t  \t\t\t\tAll vehicles are one of each. All Pre-Owned or Certified vehicles are Used. Tax, Title, and Tags are not\n\t\t  \t\t\t\tincluded in vehicle prices shown and must be paid by the purchaser. All offers expire on close of business\n\t\t  \t\t\t\tthe\n\t\t  \t\t\t\tday subject content is removed from website, and all financing is subject to credit approval. All\n\t\t  \t\t\t\ttransactions\n\t\t  \t\t\t\tare negotiable including price, trade allowance, interest rate (of which the dealer may retain a portion),\n\t\t  \t\t\t\tterm,\n\t\t  \t\t\t\tand documentary service fee. $85 dealer documentation charge is non-negotiable. Any agreement is subject to\n\t\t  \t\t\t\texecution of contract documents. While great effort is made to ensure the accuracy of the information on\n\t\t  \t\t\t\tthis\n\t\t  \t\t\t\tsite, errors do occur so please verify information with a customer service rep. This is easily done by\n\t\t  \t\t\t\tcalling\n\t\t  \t\t\t\tus at 559-435-5000 or by visiting us at the dealership.\n\t\t  \t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n\t\t  \t\t\t\tNew and used prices do not include government fees, and taxes, any finance charges, any dealer document\n\t\t  \t\t\t\tprocessing charge, any electronic filing charge, and any emission testing charge. Notice, all of our\n\t\t  \t\t\t\tvehicles\n\t\t  \t\t\t\thave an anti-theft system installed. The advertised price excludes the purchase of this anti-theft system.\n\t\t  \t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n\t\t  \t\t\t\t**With approved credit. Terms may vary. Monthly payments are only estimates derived from the vehicle price\n\t\t  \t\t\t\twith\n\t\t  \t\t\t\ta 72-month term, 4.9% interest, and 20% down payment.\n\t\t  \t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n\t\t  \t\t\t\tSome of our used vehicles may be subject to unrepaired safety recalls. Check for a vehicle’s unrepaired\n\t\t  \t\t\t\trecalls\n\t\t  \t\t\t\tby VIN at "
                      ),
                      _c(
                        "a",
                        { attrs: { href: "https://vinrcl.safecar.gov/vin/" } },
                        [_vm._v("Check for Recalls Here")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n\t\t  \t\t\t\t*MPG on non-hybrid vehicles based on EPA mileage ratings. Use for comparison purposes only. Your mileage\n\t\t  \t\t\t\twill\n\t\t  \t\t\t\tvary depending on how you drive and maintain your vehicle, driving conditions, and other factors.\n\t\t  \t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n\t\t  \t\t\t\t*MPG for hybrid vehicles based on EPA mileage ratings. Use for comparison purposes only. Your mileage will\n\t\t  \t\t\t\tvary\n\t\t  \t\t\t\tdepending on how you drive and maintain your vehicle, driving conditions, battery-pack age/condition (hybrid\n\t\t  \t\t\t\ttrims), and other factors.\n\t\t  \t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n\t\t  \t\t\t\tWhile every effort has been made to ensure display of accurate data, the vehicle listings within this web\n\t\t  \t\t\t\tsite\n\t\t  \t\t\t\tmay not reflect all accurate vehicle items including photos, information appearing herein are presented and\n\t\t  \t\t\t\tdeclared to the user on as “as-is” basis without any warranties of any kind, either expressed or implied,\n\t\t  \t\t\t\tincluding but not limited to the implied warranties of merchantability. All inventory listed is subject to\n\t\t  \t\t\t\tprior\n\t\t  \t\t\t\tsale. The vehicle photo displayed may be an example only. Please see the dealer for details. Some vehicles\n\t\t  \t\t\t\tshown\n\t\t  \t\t\t\twith optional equipment. See the actual vehicle for complete accuracy of features, options & pricing.\n\t\t  \t\t\t\tBecause of\n\t\t  \t\t\t\tthe numerous possible combinations of vehicle models, styles, colors and options, the vehicle pictures on\n\t\t  \t\t\t\tthis\n\t\t  \t\t\t\tsite may not match your vehicle exactly; however, it will match as closely as possible. Some vehicle images\n\t\t  \t\t\t\tshown are stock photos and may not reflect your exact choice of vehicle, color, trim and specification. Not\n\t\t  \t\t\t\tresponsible for pricing or typographical errors. Please verify individual inventory price(s) with the\n\t\t  \t\t\t\tdealer.\n\t\t  \t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n\t\t  \t\t\t\tAll information pertaining to any vehicle should be verified through the dealer.\n\t\t  \t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n\t\t  \t\t\t\t*Notice regarding Security System: All of our vehicles are equipped with an anti-theft device to prevent\n\t\t  \t\t\t\ttheft\n\t\t  \t\t\t\tfrom our inventory. The advertised price for all of our vehicles does not include the price of the\n\t\t  \t\t\t\tanti-theft\n\t\t  \t\t\t\tdevice. This device can be purchased for an additional cost, or may be removed at the customer’s option.\n\t\t  \t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n\t\t  \t\t\t\tAny image of a stock photo may not reflect the model year or color of, and/or equipment offered on, the\n\t\t  \t\t\t\tadvertised vehicle. The image is shown for illustration purposes only.\n\t\t  \t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n\t\t  \t\t\t\tProposition 65 Warning: Operating, servicing, and maintaining a passenger vehicle or off-road vehicle can\n\t\t  \t\t\t\texpose\n\t\t  \t\t\t\tyou to chemicals including engine exhaust, carbon monoxide, phthalates, and lead, which are known to the\n\t\t  \t\t\t\tState\n\t\t  \t\t\t\tof California to cause cancer and birth defects or other reproductive harm. To minimize exposure, avoid\n\t\t  \t\t\t\tbreathing exhaust, do not idle the engine except as necessary, service your vehicle in a well-ventilated\n\t\t  \t\t\t\tarea,\n\t\t  \t\t\t\tand wear gloves or wash your hands frequently when servicing your vehicle. For more information go to "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://www.p65warnings.ca.gov/products/passenger-vehicle/",
                          },
                        },
                        [_vm._v("p65Warnings")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("ADM = Added Dealer Markup")]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          staticStyle: {
            "padding-left": "unset !important",
            "margin-top": "4rem",
          },
          attrs: {
            id: "mobileFilterModal",
            title: "Filters",
            size: "xl",
            "hide-footer": "",
            centered: "",
            static: true,
          },
        },
        [
          _c("refinement-list", {
            attrs: {
              title: "Certification",
              attrs: [{ name: "certification" }],
            },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("refinement-list", {
            attrs: {
              title: "Make, Model & Trim",
              attrs: [
                {
                  name: "make",
                  header: true,
                },
                {
                  name: "model",
                  header: true,
                },
                {
                  name: "trim",
                  header: true,
                },
              ],
            },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("vue-slider-comp", {
            attrs: { title: "Price", attrs: [{ name: "price" }] },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("vue-slider-comp", {
            attrs: {
              title: "Year & Mileage",
              attrs: [
                {
                  name: "year",
                  header: true,
                },
                {
                  name: "mileage",
                  header: true,
                },
              ],
            },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("refinement-list", {
            attrs: { title: "Body Type", attrs: [{ name: "body" }] },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("vue-slider-comp", {
            attrs: {
              title: "Miles Per Gallon",
              attrs: [
                {
                  name: "highway_mpg",
                  header: true,
                },
                {
                  name: "city_mpg",
                  header: true,
                },
              ],
            },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("refinement-list", {
            attrs: {
              title: "Drive Train",
              attrs: [{ name: "drivetrain_description" }],
            },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("refinement-list", {
            attrs: {
              title: "Cylinders & Door Count",
              attrs: [
                {
                  name: "cylinders",
                  header: true,
                },
                {
                  name: "door_count",
                  header: true,
                },
              ],
            },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("refinement-list", {
            attrs: {
              title: "Interior Material",
              attrs: [{ name: "interior_material" }],
            },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("refinement-list", {
            attrs: {
              title: "Color",
              attrs: [{ name: "color", colorItem: true }],
            },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("refinement-list", {
            attrs: {
              title: "Fuel Type & Engine",
              attrs: [
                {
                  name: "fuel",
                  header: true,
                },
                {
                  name: "engine",
                  header: true,
                },
              ],
            },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("refinement-list", {
            attrs: { title: "Transmission", attrs: [{ name: "transmission" }] },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("refinement-list", {
            attrs: { title: "Features", attrs: [{ name: "feature_list" }] },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          staticStyle: {
            "padding-left": "unset !important",
            "margin-top": "4rem",
          },
          attrs: {
            id: "details-disclaimer-modal",
            title: "Details Disclaimer",
            size: "lg",
            "hide-footer": "",
            centered: "",
            static: true,
          },
        },
        [
          _c("p", [
            _vm._v(
              "\n        All vehicles are one of each. All Pre-Owned or Certified vehicles are\n        Used. Tax, Title, and Tags are not included in vehicle prices shown and\n        must be paid by the purchaser. All offers expire on close of business\n        the day subject content is removed from website, and all financing is\n        subject to credit approval. All transactions are negotiable including\n        price, trade allowance, interest rate (of which the dealer may retain a\n        portion), term, and documentary service fee. $85 dealer documentation\n        charge is non-negotiable. Any agreement is subject to execution of\n        contract documents. While great effort is made to ensure the accuracy of\n        the information on this site, errors do occur so please verify\n        information with a customer service rep. This is easily done by calling\n        us at 559-435-5000 or by visiting us at the dealership.\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        New and used prices do not include government fees, and taxes, any\n        finance charges, any dealer document processing charge, any electronic\n        filing charge, and any emission testing charge. Notice, all of our\n        vehicles have an anti-theft system installed. The advertised price\n        excludes the purchase of this anti-theft system.\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        **With approved credit. Terms may vary. Monthly payments are only\n        estimates derived from the vehicle price with a 72-month term, 4.9%\n        interest, and 20% down payment.\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        Some of our used vehicles may be subject to unrepaired safety recalls.\n        Check for a vehicle’s unrepaired recalls by VIN at\n        "
            ),
            _c("a", { attrs: { href: "https://vinrcl.safecar.gov/vin/" } }, [
              _vm._v("Check for Recalls Here"),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        *MPG on non-hybrid vehicles based on EPA mileage ratings. Use for\n        comparison purposes only. Your mileage will vary depending on how you\n        drive and maintain your vehicle, driving conditions, and other factors.\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        *MPG for hybrid vehicles based on EPA mileage ratings. Use for\n        comparison purposes only. Your mileage will vary depending on how you\n        drive and maintain your vehicle, driving conditions, battery-pack\n        age/condition (hybrid trims), and other factors.\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        While every effort has been made to ensure display of accurate data, the\n        vehicle listings within this web site may not reflect all accurate\n        vehicle items including photos, information appearing herein are\n        presented and declared to the user on as “as-is” basis without any\n        warranties of any kind, either expressed or implied, including but not\n        limited to the implied warranties of merchantability. All inventory\n        listed is subject to prior sale. The vehicle photo displayed may be an\n        example only. Please see the dealer for details. Some vehicles shown\n        with optional equipment. See the actual vehicle for complete accuracy of\n        features, options & pricing. Because of the numerous possible\n        combinations of vehicle models, styles, colors and options, the vehicle\n        pictures on this site may not match your vehicle exactly; however, it\n        will match as closely as possible. Some vehicle images shown are stock\n        photos and may not reflect your exact choice of vehicle, color, trim and\n        specification. Not responsible for pricing or typographical errors.\n        Please verify individual inventory price(s) with the dealer.\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        All information pertaining to any vehicle should be verified through the\n        dealer.\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        *Notice regarding Security System: All of our vehicles are equipped with\n        an anti-theft device to prevent theft from our inventory. The advertised\n        price for all of our vehicles does not include the price of the\n        anti-theft device. This device can be purchased for an additional cost,\n        or may be removed at the customer’s option.\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        Any image of a stock photo may not reflect the model year or color of,\n        and/or equipment offered on, the advertised vehicle. The image is shown\n        for illustration purposes only.\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        Proposition 65 Warning: Operating, servicing, and maintaining a\n        passenger vehicle or off-road vehicle can expose you to chemicals\n        including engine exhaust, carbon monoxide, phthalates, and lead, which\n        are known to the State of California to cause cancer and birth defects\n        or other reproductive harm. To minimize exposure, avoid breathing\n        exhaust, do not idle the engine except as necessary, service your\n        vehicle in a well-ventilated area, and wear gloves or wash your hands\n        frequently when servicing your vehicle. For more information go to\n        "
            ),
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.p65warnings.ca.gov/products/passenger-vehicle/",
                },
              },
              [_vm._v("p65Warnings")]
            ),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("ADM = Added Dealer Markup")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }